import React, {useEffect,useState} from 'react'
import { Link } from 'react-router-dom';
import '../../MainStyle.css';
import { setCookie,getCookie } from '../modals/Cookie';
import { API_ACCESS_URL } from '../modals/Constants';

const bettingDialog = ({intentData,updateState,toastUpdate}) => {

    const [dialogData, setDialogData] = useState({
        contractAmnt: 10,
        contracTotalAmnt: 10,
        numLot: 1,
        loadingStatus: false,
    });

    
    const updateContractData = (data) => {
        updateTotalContractAmnt(dialogData.numLot,data);

        setDialogData(previousState => {
            return { ...previousState, contractAmnt: data }
        });
    }

    const updateTotalContractAmnt = (lotSize,contractAmnt) =>{
        let updatedContractAmount = lotSize * contractAmnt;

        setDialogData(previousState => {
            return { ...previousState, contracTotalAmnt: updatedContractAmount }
        });
    }

    const updateLotData = (data) => {
        let updatedData = dialogData.numLot + Number(data);

        if(updatedData >= 1 && updatedData < 200){
            updateTotalContractAmnt(updatedData, dialogData.contractAmnt);

            setDialogData(previousState => {
                return { ...previousState, numLot: updatedData }
            });
        }
        
    }

    const resetDialogData = (e) => {
        if(e!=null && e.target.className=="ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg activeDialog"){
            setDialogData(previousState => {
                return { ...previousState, contractAmnt: 10 }
            });
    
            setDialogData(previousState => {
                return { ...previousState, contracTotalAmnt: 10 }
            });
    
            setDialogData(previousState => {
                return { ...previousState, numLot: 1 }
            });

            setDialogData(previousState => {
                return { ...previousState, loadingStatus: false }
            });
        }
    }

    const confirmBet = (intentData) => {

        setDialogData(previousState => {
            return { ...previousState, loadingStatus: true }
        });


        async function fecthApiData(url,formData) {
  
            try {
              const res = await fetch(url, {
                method: "POST",
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': getCookie("secret"),
                },
                body: JSON.stringify(formData),
              });

              const data = await res.json();

              updateState(null,'dismiss','');
              
              if(data.status_code=="success"){
                setCookie("balance", data.account_balance, 30);
                toastUpdate(true, "Bet Success");
              }else if(data.status_code=="balance_error"){
                toastUpdate(true, "Your balance is insufficient ! Please recharge now !");
              }else if(data.status_code=="account_error"){
                toastUpdate(true, "Account Problem ! Please try again!");
              }else if(data.status_code=="betting_timeout_error"){
                toastUpdate(true, "Oops! Betting timeout! Please try again!");
              }else if(data.status_code=="auth_error"){
                toastUpdate(true, "Authentication Error! Please login again!");
              }else{
                toastUpdate(true, "There was a technical issue! Please try again!");
              }

              setDialogData(previousState => {
                return { ...previousState, loadingStatus: false }
              });
              
            } catch (error) {
              toastUpdate(true, "There was a technical issue! Please try again!");
            }
        };

        const formData = { USER_ID: getCookie("uid"),BET_ON: intentData.gameSelectedColor,NUM_LOT: dialogData.numLot,CONTRACT_AMOUNT:  dialogData.contractAmnt};

        fecthApiData(API_ACCESS_URL+"games-api/"+intentData.gameFolderName.toLowerCase()+"/request-bet.php",formData);
    }

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black bt-dlg ${intentData.bettingDialogShow=="true" ? 'activeDialog' : ''}`} onClick={(e)=>{updateState(e,'false','','');resetDialogData(e)}}>
              <div className={`dlg-c ps-fx ps-btm z-i-1000 res-wth bg-white pd-15 ${intentData.bettingDialogTheme}`}>
                <p className='ft-sz-23 w-100 mg-b-10 dlg-thm-txt txt-a-center'>{intentData.pageTitle+"-"+intentData.bettingDialogTitle}</p>

                <div className='row-view sb-view h-50-p w-100 pd-30-5 br-10 br-a-l-white bg-extm-l-white'>
                    <p className='m-lft-5 ft-sz-25'>₹{getCookie("balance")}</p>
                    <Link className="txt-deco-n pd-10-15 br-10 cl-white bg-drk-orange bx-drk-orange" to={"/recharge"}>Recharge</Link>
                </div>

                <div className='col-view mg-t-10 w-100'>
                    <p>Contract Money</p>

                    <div className='row-view mg-t-5'>
                        <div className={`smpl-btn ft-sz-16 ${dialogData.contractAmnt=="10" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('10')}>10</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogData.contractAmnt=="100" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('100')}>100</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogData.contractAmnt=="1000" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('1000')}>1000</div>
                        <div className={`smpl-btn mg-l-5 ft-sz-16 ${dialogData.contractAmnt=="10000" ? 'a-smpl-btn' : ''}`} onClick={()=>updateContractData('10000')}>10000</div>
                    </div>
                </div>

                <div className='col-view mg-t-15 w-100'>
                    <p>Number</p>

                    <div className='row-view sb-view mg-t-5'>
                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('-5')}>-5</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('-1')}>-1</div>
                        </div>

                        <h3 className='ft-sz-25 cl-red'>{dialogData.numLot}</h3>

                        <div className='row-view'>
                          <div className='smpl-btn ft-sz-16' onClick={()=>updateLotData('1')}>+1</div>
                          <div className='smpl-btn ft-sz-16 mg-l-5' onClick={()=>updateLotData('5')}>+5</div>
                        </div>
                        
                    </div>
                </div>

                <p className='mg-t-15 w-100'>Total contract money is {dialogData.contracTotalAmnt}</p>
                <div className='w-100 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-15 bg-cyan-blue bx-cyan-blue' onClick={()=>confirmBet(intentData)}>
                    <img className={`smpl-btn-l spin-anim ${dialogData.loadingStatus==false ? 'hide-v' : ''}`} src={require('../icons/loader-icon.png')} />
                    <span className={`${dialogData.loadingStatus==true ? 'hide-v' : ''}`}>Continue</span>
                </div>
              </div>
        </div>
    );
};

export default bettingDialog;