import React from 'react'
import { Link } from 'react-router-dom';

function RewardOptions(){
    return (
        <div className="w-100 v-center">
            <Link className="txt-deco-n row-view a-center" to={"/rewards"}>
              <img className='h-w-36' src={require('../icons/task_icon.png')} />
              <span className='mg-l-10 cl-black'>Task reward</span>
            </Link>

            {/* <div className="reward-view-right">
              <img src={require('../icons/checkin_icon.png')} />
              <span>Check In</span>
            </div> */}
        </div>
    );
}

export default RewardOptions;