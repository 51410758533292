import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL } from '../modals/Constants';
import { getCookie } from '../modals/Cookie';

function TodaysReports(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Today Reports",
        isLoadingShow: false,
        totalIncome: 0,
        totalInvite: 0,
        todayInvite: 0,
        todayActive: 0,
        teamBalance: 0,
        teamTotalBet: 0,
        teamBetAmount: 0,
        totalWithdrawls: 0,
        totalWithdrawlAmount: 0,
        totalRecharges: 0,
        totalRechargeAmount: 0,
        totalBettingCommission: 0,
        totalRechargeCommission: 0,
        recordList: [],
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateRecordList = (data) => {
      let tempData = [];

        for (let i = 0; i < data.length; i++) {          
            tempData.push(
              <div key={i} className="row-view sb-view pd-10-15 bg-extm-l-white br-a-l-blue br-5 mg-t-15">
                <div className='row-view'>
                 <img className='h-w-32' src={require('../icons/rupee_icon.png')} />

                 <div className='col-view mg-l-20'>
                  <span className='ft-sz-17'>{data[i]['t_title']}</span>
                  <span className='ft-sz-12 mg-t-5'>{data[i]['t_time_stamp']}</span>
                 </div>
                </div>
                <span className='ft-sz-17 ft-wgt-b cl-green'>₹{data[i]['t_amount']}</span>
              </div>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getGameRecords(){
        const fecthApiData = async (url) => {
            try {
              const res = await fetch(url);
              const data = await res.json();
              console.log(data);
              updateLoadingStatus(false);

              if(data.status_code=="success"){
                
                setConstants(previousState => {
                  return { ...previousState, totalIncome: data.data[0].total_income }
                });

                setConstants(previousState => {
                  return { ...previousState, totalBettingCommission: data.data[0].total_bet_income }
                });
                
                setConstants(previousState => {
                  return { ...previousState, totalRechargeCommission: data.data[0].total_recharge_income }
                });
                
                setConstants(previousState => {
                  return { ...previousState, totalInvite: data.data[0].total_invite }
                });

                setConstants(previousState => {
                  return { ...previousState, todayInvite: data.data[0].todays_invite }
                });

                setConstants(previousState => {
                  return { ...previousState, todayActive: data.data[0].today_active }
                });

                setConstants(previousState => {
                 return { ...previousState, teamBalance: data.data[0].team_balance }
                });

                setConstants(previousState => {
                  return { ...previousState, teamTotalBet: data.data[0].team_total_bet }
                });

                setConstants(previousState => {
                  return { ...previousState, teamBetAmount: data.data[0].team_bet_amount }
                });

                setConstants(previousState => {
                  return { ...previousState, totalRecharges: data.data[0].total_num_recharge }
                });

                setConstants(previousState => {
                  return { ...previousState, totalRechargeAmount: data.data[0].total_recharge }
                });

                setConstants(previousState => {
                  return { ...previousState, totalWithdrawls: data.data[0].total_num_withdraw }
                });

                setConstants(previousState => {
                  return { ...previousState, totalWithdrawlAmount: data.data[0].total_withdraw }
                });
              }

            } catch (error) {
              updateLoadingStatus(false);
            }
        };

        updateLoadingStatus(true);
        fecthApiData(API_ACCESS_URL+"load-today-reports.php?USER_ID="+getCookie("uid"));
    }

    useEffect(() => {
        getGameRecords();
    }, []);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

               <div className='col-view pd-5-15'>
                  <div className="col-view g-v-3 mg-t-20">

                    <div className="col-view v-center pd-15-0 br-a-l-grey">
                        <p>{pageConst.totalIncome}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Total Agent Income</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey">
                        <p>{pageConst.totalBettingCommission}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Betting Commission</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey">
                        <p>{pageConst.totalRechargeCommission}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Recharge Commission</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey">
                        <p>{pageConst.totalInvite}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Total Users</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.todayActive}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Today Active</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.todayInvite}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Today Register</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.teamBalance}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Team Balance</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.teamTotalBet}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Total Bet</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.teamBetAmount}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Team Bet Amount</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.totalRecharges}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Recharge Number</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.totalRechargeAmount}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Recharge Amount</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.totalWithdrawls}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Withdrawl Number</p>
                    </div>

                    <div className="col-view v-center pd-15-0 br-a-l-grey pd-15-0">
                        <p>{pageConst.totalWithdrawlAmount}</p>
                        <p className='txt-a-center ft-sz-14 mg-t-5'>Withdrawl Amount</p>
                    </div>

                  </div>
                </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default TodaysReports;