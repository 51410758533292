import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import '../css/navbar.css';

const BottomNavbar = ({activeBar}) => {

    return (
      <div className="row-view">

        <nav className='btm-navbar ps-fx ps-btm z-100 bg-white res-wth'>
            
          <Link className={`nav-col ${activeBar=="home" ? 'opac-f' : ''}`} to={"/home"}>
            <img src={require('../icons/home_s.png')} alt="home" />
            <p>Home</p>
          </Link>

          <Link className={`nav-col ${activeBar=="invite" ? 'opac-f' : ''}`} to={"/invite"}>
            <img src={require('../icons/group_s.png')} alt="invite" />
            <p>Invite</p>
          </Link>

          <Link className={`nav-col ${activeBar=="recharge" ? 'opac-f' : ''}`} to={"/recharge"}>
            <img src={require('../icons/wallet_s.png')} alt="recharge" />
            <p>Recharge</p>
          </Link>

          <Link className={`nav-col ${activeBar=="my" ? 'opac-f' : ''}`} to={"/my"}>
            <img src={require('../icons/my_s.png')} alt="my" />
            <p>My</p>
          </Link>

        </nav>

      </div>
    );
};

export default BottomNavbar;