export const WEBSITE_NAME = "Bigfan Club";
export const WEBSITE_URL = "https://bigfanclub.online/";
export const API_ACCESS_URL = "https://api.bigfanclub.online/";
export const PG_ACCESS_URL = "https://bigfanclub.online/pg/";
export const LOGIN_REDIRECT_URL = WEBSITE_URL+"LG";
export const HOME_REDIRECT_URL = WEBSITE_URL+"home";
export const CUSTOM_USER_AGENT = "NINJA_CRIPT_1.0.0.1";

export function redirectTo(url) {
    window.location.replace(url);
}

export function openNewPage(url) {
    window.location.href =url;
}

export function getURLParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function getInternetStatus(){
    if(navigator.onLine){
        return true;
    }else{
        return false;
    }
}

export function generateReferalURL(USER_ID){
    return WEBSITE_URL+"RG?C="+USER_ID;
}

export function copyText(text){

    var textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.opacity = "0"; 
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (err) {
      return true;
    }

    return true;
}

export function generateAuthToken(number){
    let deepCode = (number-44785410)+""+number.toString().charAt(1)+""+(number-365005954)+""+(number-56841056)+""+(number+21452417)+""+number.toString().charAt(2)+""+(number-65124785)+"CN"+(number-86328714)+""+(number+25874517);
    return 'AUTH_'+deepCode+'_DS:NINJA_CRYPT';
}