import React,{useState, useEffect} from 'react'
import RewardOptions from '../other-components/RewardOptions';
import { setCookie,getCookie,deleteCookie } from '../modals/Cookie';
import { API_ACCESS_URL,LOGIN_REDIRECT_URL,redirectTo } from '../modals/Constants';
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';

function Home(){
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);
  const [pageConst, setConstants] = useState({
    accountBalance: 0,
    recordList: [],
  });

  function getAccountInfo(){
    setLoadingShow(true);

    const fecthApiData = async (url) => {
      
      try {
        const res = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getCookie("secret"),
          }
        });
        const data = await res.json();
        
        setLoadingShow(false);

        if(data.status_code=="success"){  
          let accountMobileNum = data.data[0]['account_mobile_num'];
          let accountBalance = data.data[0]['account_balance'];
          let accountWinningBalance = data.data[0]['account_w_balance'];
          let accountCommissionBalance = data.data[0]['account_c_balance'];
          let accountIsAgent = data.data[0]['account_is_agent'];
          let service_app_status = data.data[0]['service_app_status'];
          let service_min_recharge = data.data[0]['service_min_recharge'];
          let service_min_withdraw = data.data[0]['service_min_withdraw'];
          let service_recharge_option = data.data[0]['service_recharge_option'];
          let service_active_pg = data.data[0]['service_active_pg'];
          let service_telegram_url = data.data[0]['service_telegram_url'];
          let service_app_download_url = data.data[0]['service_app_download_url'];
    
          setCookie("balance", accountBalance, 30);
          setCookie("cbalance", accountCommissionBalance, 30);
          setCookie("wbalance", accountWinningBalance, 30);
          setCookie("minrecharge", service_min_recharge, 30);
          setCookie("minwithdraw", service_min_withdraw, 30);
          setCookie("rechargeoptions", service_recharge_option, 30);
          setCookie("isAccountAgent", accountIsAgent, 30);
          setCookie("telegramURL", service_telegram_url, 30);
          setCookie("activePG", service_active_pg, 30);
          setCookie("appDownloadURL", service_app_download_url, 30);
          setConstants({...pageConst, accountBalance: accountBalance});
  
          if(service_app_status=="OFF"){
            navigate('/um', { replace: true });
          }
        }else if(data.status_code=="authorization_error"){
          if(deleteCookie(1)){
            navigate('/LG', { replace: true });
          }
        }
        
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if(!isLoadingShow){
      fecthApiData(API_ACCESS_URL+"request-account-info.php?USER_ID="+getCookie("uid"));
    }

  }

  useEffect(() => {
    if(getCookie("uid")){
      getAccountInfo();
    }else{
      redirectTo(LOGIN_REDIRECT_URL);
    }
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y hide-sb">
        <div className="row-view sb-view mg-t-25 pd-15">
          <div className="col-view mg-l-15">
            <p>Balance</p>
            <div className="row-view a-center mg-t-10">
              <span className="ft-sz-25 ft-wgt-b">₹{pageConst.accountBalance}</span>
              <img className={`h-20-p mg-l-10 ${isLoadingShow ? 'spin-anim' : ''}`} src={require('../icons/refresh_icon.png')} onClick={() => getAccountInfo()}/>
            </div>
            <div className="ft-sz-16 cl-l-grey mg-t-10">ID:{getCookie("uid")}</div>
          </div>

          <div className="col-view">
            <Link className="txt-deco-n pd-10 br-10 ft-sz-15 cl-white ft-wgt-b bg-blue" to={"/recharge"}>Recharge</Link>
            <Link className="txt-deco-n pd-10 br-10 ft-sz-15 cl-black ft-wgt-b bg-grey mg-t-10" to={"/withdraw?M=W"}>Withdraw</Link>
          </div>
        </div>

        <RewardOptions />

        <div className='w-100 mg-t-15 pd-5-15'>
          <Link to={"/invitelink"}>
            <img className='br-8' src={require("../icons/refer_now_banner.svg")} />
          </Link>
        </div>
        
        <div className="available-games-view">
          <Link to={"/FastParity"}>
            <img className='w-100 br-8' src={require("../icons/fast_parity_banner.jpg")} />
          </Link>

          <Link to={"/Spare"}>
            <img className='w-100 br-8' src={require("../icons/spare_banner.jpg")} />
          </Link>

          <Link to={"/Parity"}>
            <img className='w-100 br-8' src={require("../icons/parity_banner.jpg")} />
          </Link>

          {/* <Link to={"/Dice"}>
            <img className='w-100 br-8' src={require("../icons/dice_banner.jpg")} />
          </Link>

          <Link to={"/AndarBahar"}>
            <img className='w-100 br-8' src={require("../icons/andar_bahar_banner.jpg")} />
          </Link>

          <Link to={"/Circle"}>
            <img className='w-100 br-8' src={require("../icons/wheelocity_banner.png")} />
          </Link>

          <Link to={"/GoldMiner"}>
            <img className='w-100 br-8' src={require("../icons/goldminer_banner.png")} />
          </Link>

          <Link to={"/Aviator"}>
            <img className='w-100 br-8' src={require("../icons/jet_banner.png")} />
          </Link>

          <Link to={"/DragonTiger"}>
            <img className='w-100 br-8' src={require("../icons/dragontiger_banner.jpg")} />
          </Link> */}
        </div>

        <BottomNavbar activeBar="home"/>
      </div>
    </div>
  );
}

export default Home;