import React,{useEffect} from "react";
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';
import { setCookie,getCookie,deleteCookie } from '../modals/Cookie';
import { openNewPage,redirectTo } from '../modals/Constants';

function My() {
  const navigate = useNavigate();
  const signOutAccount = () =>{
    if(deleteCookie(1)){
      navigate('/LG', { replace: true });
    }
  }

  useEffect(() => {
    if(!getCookie("uid")){
      navigate('/LG', { replace: true });
    }
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y hide-sb">

        <BottomNavbar activeBar="my"/>

        <div className="flt-view w-100">
          <div className="pr-v w-100 h-60-p bg-blue">
            <div className="flt-view-details pd-10">
              <div className="row-view">
                <img
                  src={require("../icons/avatar_icon.png")}
                  className="h-w-45"
                  alt="icon"
                />

                <div className="col-view mg-l-15">
                  <span className="ft-sz-18">{getCookie("uname") != "" ? getCookie("uname") : getCookie("mobile")}</span>
                  <span className="ft-sz-12 mg-t-5">
                    Mob: {getCookie("mobile")}, ID: {getCookie("uid")}
                  </span>
                </div>
              </div>

              <div className="row-view mg-t-10">
                <div className="h-40-p pd-5-15 bg-white br-5 ft-sz-13 v-center br-a-blue">
                  Change Nick Name
                </div>
                <Link className="h-40-p pd-5-15 br-5 ft-sz-13 v-center txt-deco-n bg-white cl-black br-a-blue mg-l-5" to={"/change-password"}>
                  Change Password
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-grey mg-t-5em">

          <div className="col-view bg-white pd-10">

            <Link className={`row-view cl-black bg-white pd-15 sb-view txt-deco-n ${getCookie("isAccountAgent")!='true' ? 'hide-v' : ''}`} to={"/todaysreports"}>
            <div className="v-center">
              <img src={require("../icons/order_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Today Reports</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon" />
            </Link>

            <Link className="row-view cl-black bg-white pd-15 sb-view txt-deco-n" to={"/myorders"}>
            <div className="v-center">
              <img src={require("../icons/order_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Order Record</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon" />
            </Link>

            <span className='line-hv-grey'></span>

            <Link className="row-view cl-black bg-white pd-15 sb-view txt-deco-n" to={"/transactions"}>
            <div className="v-center">
              <img src={require("../icons/finantial_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Financial Details</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon"/>
            </Link>

          </div>

          <div className="col-view bg-white pd-10 mg-t-15">
            
            <div className="row-view bg-white pd-15 sb-view" onClick={() => openNewPage(getCookie("appDownloadURL"))}>
            <div className="v-center">
              <img src={require("../icons/download_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Download</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon"
            />
            </div>

            <span className='line-hv-grey'></span>

            <div className="row-view bg-white pd-15 sb-view" onClick={() => openNewPage(getCookie("telegramURL"))}>
            <div className="v-center">
              <img src={require("../icons/telegram_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Follow us</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon"
            />
            </div>

            <span className='line-hv-grey'></span>

            <div className="row-view bg-white pd-15 sb-view" onClick={() => openNewPage(getCookie("telegramURL"))}>
            <div className="v-center">
              <img src={require("../icons/support_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Support</span>
            </div>
            
            <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon"
            />
            </div>

            <span className='line-hv-grey'></span>

            <Link className="row-view cl-black bg-white pd-15 sb-view txt-deco-n" to={"/helpsupport"}>
             <div className="v-center">
              <img src={require("../icons/complain_icon.png")} className="h-w-28" alt="icon" />
              <span className="mg-l-10">Complaint</span>
             </div>
            
             <img
              src={require("../icons/arrowRight_icon.png")}
              className="h-15-p"
              alt="icon"/>
            </Link>

          </div>

          <div className="col-view bg-white v-center pd-30 mg-t-15 mg-b-70">
            <div className="mg-t-15 txt-deco-u pd-10-15" onClick={() => signOutAccount()}>Sign Out</div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default My;
