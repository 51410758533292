import React, { useEffect, useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import BottomNavbar from '../other-components/BottomNavbar';
import { getCookie, setCookie } from "../modals/Cookie";
import { API_ACCESS_URL,getInternetStatus,redirectTo } from "../modals/Constants";

  function Invite() {
  const navigate = useNavigate();
  const [pageConst, setConstants] = useState({
      gameTitle: "Invite",
      isLoadingShow: false,
      todaysEarning: 0,
      todaysInvite: 0,
      totalInvite: 0,
      totalIncome: 0,
      recordList: [],
  });

  const updateLoadingStatus = (data) => {
      setConstants(previousState => {
          return { ...previousState, isLoadingShow: data }
      });
  }

  const updateRecordList = (data) => {
    let tempData = [];
    let dataList = data.data;

      for (let i = 0; i < dataList.length; i++) {          
          tempData.push(
            <div key={i} className="row-view sb-view w-100 mg-t-10">
              <div className="h-w-36 br-50 v-center cl-white bg-drk-blue">{dataList[i]['t_msg']=='Level 2' ? 'Lv2' : dataList[i]['t_msg']=='Level 2' ? 'Lv2' : 'Lv1'}</div>
              
              <div className="row-view sb-view w-85">
                <div className="col-view">
                  <span className="ft-sz-17">{dataList[i]['t_msg']!='Recharge Bonus' ? dataList[i]['t_msg']+'- Commission' : dataList[i]['t_msg']}</span>
                  <div className="row-view ft-sz-12 cl-l-grey mg-t-5">
                   <span>{dataList[i]['t_date']+' '+dataList[i]['t_time']}</span>
                   <span className="mg-l-15">from {dataList[i]['t_receive_from']}</span>
                  </div>
                </div>

                <span>+₹{dataList[i]['t_amount']}</span>
              </div>

            </div>)

            if(dataList.length-1 > i){
              tempData.push(
                <div key={i+25} className="line-hv-grey mg-t-10"></div>
               )
            }
            
      };

      setConstants({...pageConst, todaysEarning: data['todays_income'], todaysInvite: data['todays_invite'], totalInvite: data['total_invite'], totalIncome: data['total_income']});
      setConstants(previousState => {
          return { ...previousState, recordList: tempData }
      });
  }


  function getCommissionRecords(){
      const fecthApiData = async (url) => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        console.log(data);

          if(data.status_code="success"){
            setCookie("cbalance", data.total_income, 30);
            updateRecordList(data); 
          }
      } catch (error) {
      }
    };

    fecthApiData(API_ACCESS_URL+"load-commission-records.php?USER_ID="+getCookie("uid")+"&PAGE_NUM=1");
  }

  useEffect(() => {
    if(!getCookie("uid")){
      navigate('/LG', { replace: true });
    }else if(getInternetStatus()){
      getCommissionRecords();
    }
  }, []);

  return (
    <div className="v-center">
      <div className="h-100vh res-wth ovf-scrl-y hide-sb">

        <BottomNavbar activeBar="invite"/>

        <div className="flt-view w-100">
          <div className="pr-v w-100 h-60-p bg-blue">
            <div className="flt-view-details pd-15">
              <div className="row-view sb-view">
                <div className="col-view mg-l-15">
                  <span className="ft-sz-12">Agent amount</span>
                  <span className="ft-sz-25 ft-wgt-600 mg-t-5">
                  ₹{getCookie("cbalance")}
                  </span>
                </div>

                <Link className='txt-deco-n bg-drk-blue cl-white pd-10-15 br-5' to={"/withdraw?M=C"}>Withdraw</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-grey mg-t-30">

          <div className="col-view bg-white pd-5-15">

            <div className="w-100 pd-10 v-center">
              <Link className='txt-deco-n cl-grey pd-10-15' to={"/invitelink"}>My Link</Link>
            </div>

          </div>

          <div className="col-view bg-white pd-5-15 mg-t-15">
            
            <div className="row-view bg-white pd-15 sb-view">
              <div className="col-view v-center">
                <span className="ft-sz-12 cl-l-grey">Invited today</span>
                <span className="ft-sz-20 ft-wgt-600 mg-t-15">
                  {pageConst.todaysInvite}
                </span>
                <div className="ft-sz-12 mg-t-15">
                  Total
                  <span className="ft-sz-12 ft-wgt-600 mg-l-5">
                  {pageConst.totalInvite}
                  </span>
                </div>
              </div>
            
              <div className="col-view v-center">
                <span className="ft-sz-12 cl-l-grey">Today's income</span>
                <span className="ft-sz-20 ft-wgt-600 mg-t-15">
                  ₹{pageConst.todaysEarning}
                </span>
                <div className="ft-sz-12 mg-t-15">
                  Total
                  <span className="ft-sz-12 ft-wgt-600 mg-l-5">
                  {pageConst.totalIncome}
                  </span>
                </div>
              </div>
            </div>
            
          </div>

          <div className="col-view bg-white v-center pd-30 mg-t-15 mg-b-70">
            <div className="row-view sb-view w-100">
              <span>Recent Record(s)</span>
              <Link className="txt-deco-n pd-5 cl-black ft-sz-15" to={"/recharge"}>More</Link>
            </div>

            <div className="col-view w-100 mg-t-25">
             {pageConst.recordList}
            </div>

          </div>

        </div>

      </div>
    </div>
  );
}

export default Invite;
